.footer-widgets {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 700px;
  position: relative;
  padding-top: 140px;
  padding-bottom: 4vw;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  &::after {
      content: '';
      background-image: url('/themes/default/assets/images/mask-top-flipped.svg');
      background-repeat: no-repeat;
      background-position: top center;
      position: absolute;
      top: -1px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }
    &__logo {
      position: relative;
      z-index: 1;
      margin-bottom: 80px;
      max-width: 319px;
      height: auto;
      width: auto;
    }
    &__content {
      position: relative;
      z-index: 1;
      display: grid;
      justify-content: space-between;
      grid-template-columns: repeat(3, 1fr) 25%;
      grid-template-rows: 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 40px;
  
      .title {
        color: #fff;
        text-transform: uppercase;
        font-size: rem(18px);
        font-weight: 700;
        margin-bottom: 23px;
        display: block;
      }
      
      p {
        color: #fff;
        font-size: rem(16px);
        line-height: rem(16px);
        margin-bottom: 14px;
      }

      a {
          color: #fff;
          text-decoration: underline;
          display: block;
          margin-top: 14px;
          svg {
            fill: #80BA49;
            margin-right: 20px;
            width: 26px;
            height: 26px;
          }
          &:hover {
            color:#80BA49;
          }
      }
    }

    &__links {
      margin-top: 30px;
    }

    &__social {
      align-self: flex-end;
      justify-self: flex-end;

      span {
        margin-right: 20px;
        color: #fff;
      }

      a {
        display: inline-block;
        opacity: 0.6;
        transition: ease 0.3s opacity;
        margin: 0;

        svg {
          width: 36px;
          height: 36px;
          margin-right: 0;
          margin-left: 10px;
          fill: #fff;
        }

        &:hover {
          opacity: 1;
        }
      }
      
    }

}


.footer-small{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 15px;
    padding-bottom: 15px;

    &__copyright {
      color: #757575;
    }
    
    a {
      color: #757575;
      &:hover {
        color: #80BA49;
      }
    }

    &__realization{
      display: flex;
      justify-content: flex-end;
      align-items: center;
      
      span{
        color: #757575;
        transition: color .3s ease-in-out;
      }
      &:hover span{
        color: #80BA49;
      }
      img{
        margin-left: 15px;
      }
    }
}


.footer-mobile {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #80BA49;
  justify-content: center;
  z-index: 9;
  a {
    padding: 12px 12px;
    color: #fff;
    display: flex;
    align-items: center;
    transition: ease 0.3s all;
    @include mq($until: 360px){
      &:last-of-type {
        svg {
          margin-right: 0;
        }
        span {
          display: none;
        }
      }
    }    
    &:hover {
      background-color: #1FA3CD;
    }
    span {
      color: #fff;
      font-size: 16px;
    }
    svg {
      margin-right: 10px;
      fill: #fff;
      width: 26px;
      height: 26px;
    }
  }
}
@include mq($until: md){
  .footer-mobile {
    display: flex;
  }
}

@include mq($until: xl){
  .footer-widgets {
    padding-top: 140px;

    &__content {
      grid-template-columns: repeat(3, 1fr);
    }

    &__social {
      justify-self: auto;
      grid-area: 2 / 1 / 3 / 4;
    }
  }
}

@include mq($until: md){
  .footer-widgets {
    &__content {
      grid-template-columns: 1fr;
    }

    &__col {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 40px;
      grid-row-gap: 40px;
    }

    &__social {
      justify-self: auto;
      grid-area: 4;

    }
  }
  .footer-small {
    margin-bottom: 50px;
  }
}

@include mq($until: sm){
  .footer-widgets {
    &__col {
      grid-template-columns: 1fr;
      grid-row-gap: 0;
    }

    &__links {
      margin-top: 0;
    }
  }
}

@include mq($until: xs){
  .footer-widgets {
    &__social {
      span {
        display: block;
        margin-bottom: 10px;
      }

      a svg {
        margin-left: 0;
        margin-right: 10px;
      }
    }
  }
  .footer-small {
    flex-direction: column;

    &__copyright {
      margin: 10px 0;
    }
  }

}
