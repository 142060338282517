.news-card{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    &__image {
        border-radius: 7px;
    }
    &__header{
        font-size: 1.2rem!important;
        margin-top: 20px;
        text-transform: none!important;
        transition: ease 0.3s all;
    }
    &__more{
        margin-top: auto;
        padding-top: 15px;
    }
    &:hover {
        & .news-card__header{
            color: $color-main;
        }
    }
}
