.subsite {

    &-content {
        padding-top: 4vh;

        p {
            max-width: 1100px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            color: #0A0C42;
            font-size: 1.125rem;
            line-height: 1.875rem;
        }
        h1,h2 {
            text-transform: uppercase;
            font-weight: 700;
        }
        h3 {
            font-size: rem(24px);
            font-weight: 600;
            text-transform: uppercase;
        }
        .about h3 {
            font-size: rem(18px);
            font-weight: 700;
        }
        h1,h2,h3,h4,h5, ul, ol, .leaflet-container, .row, .slick-slider, .readmore {
            max-width: 1100px;
            width: 100%;
            margin-left: auto;
            margin-right: auto;
        }
        .form {
            h2, p {
                text-align: center;
            }
            
        }
    }

    &__info {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding-top: 16vh;
        padding-bottom: 16vh;
    
        &--right {
            justify-content: flex-start;

            .egg-wrap {
                left: unset!important;
                right: -8vw;
                text-align: right!important;
            }
            .egg {
                left: unset!important;
                right: -8vw;
            }
        }

        &-content {
            z-index: 2;
            position: relative;
            width: 56%;
            padding-left: 40px;
           
            h3 {
                margin-bottom: 40px;
                margin-right: auto;
                max-width: 840px;
                font-weight: 700;
                font-size: rem(26px);
                text-transform: uppercase;
                color: #121760;
            }
            p {
                margin-left: 0;
                max-width: 840px;
            }
            a {
                margin-top: 40px;
            }
        }
        .egg-wrap {
            position: absolute;
            left: -8vw;
            overflow: hidden;
            max-width: 100vw;
            width: 100%;
            text-align: left;
            z-index: 1;
        }
        .egg {
            left: -8vw;
            max-width: 54%;
            width: 100%;
            position: relative;        
        }
    }
}

.readmore {
    border: 1px solid rgba(18, 23, 96, 0.1);
    border-radius: 10px;
    margin-bottom: 20px;

    &__more {
        display: none;
        padding: 20px;
    }
    &__button {
        font-weight: 600;
        display: block;
        cursor: pointer;
        transition: ease 0.3s color;
        position: relative;
        padding: 20px;

        &_less {
            color: $color-main;
            &::after {
                content: '-'!important;
            }
        }
        &::after {
            content: '+';
            position: absolute;
            right: 20px;
            top: 20px;
        }
        &:hover {
            color: $color-main;
        }
    }
}

@include mq($until: md){
    .subsite {

        &-content {
            padding-top: 140px;
        }
        &__info {
            display: block;
            &-content {
                padding-left: 0;
                width: 100%;
            }

            .egg-wrap {
                position: relative;
                top: 0;
                left: 0;
                margin-top: -40px;
                margin-bottom: 60px;
            }
    
            .egg {
                left: 0;
                right: 0;
                max-width: 100%;
                transform: none;        
                max-height: 380px;
                height: auto;
            }

        }
    }    
}
