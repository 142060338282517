.about {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.about-us {
        min-height: 80vh;
    }
    &.about-sieci {
        min-height: 50vh;
    }

    &__content {
        text-align: center;

        h2 {
            max-width: 800px;
            margin: 0 auto 40px auto;
            color: #121760;
            font-size: rem(34px);
            font-weight: 700;
            text-transform: uppercase;
        }

        p {
            max-width: 800px;
            margin: 0 auto;
            color: #0A0C42;
            font-size: rem(18px);
            line-height: rem(30px);
        }
    }

    &__logos {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-column-gap: 40px;
        grid-row-gap: 40px;
        align-items: center;
        justify-items: center;
        margin: 80px auto 0 auto;
        max-width: 1120px;

        img {
            filter: grayscale(1);
            transition: ease 0.3s all;
            height: auto;
            &:hover {
                filter: grayscale(0);
                transform: scale(1.1);
            }
        }
    }

    &__services {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 40px;
        align-items: center;
        justify-items: stretch;
        margin: 40px auto 0 auto;
        max-width: 900px;
        width: 100%;
    }

    &__service {
        text-align: center;

        .icon {
            position: relative;
            aspect-ratio: 4/3;
            display: flex;
            align-items: center;
            justify-content: center;        

            img {
                position: relative;
                z-index: 1;
                transition: ease 0.3s all;
                width: 100%;
                max-width: 120px;
            }

            &::after {
                content: '';
                background-image: url(/themes/default/assets/images/icons/icons-hover.svg);
                background-position: center;
                background-size: contain;
                background-repeat: no-repeat;
                position: absolute;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translateX(-50%) translateY(-50%);
                opacity: 0;
                z-index: 0;
                transition: ease 0.3s opacity;
            }
        }

        h3 {
            font-size: rem(18px);
            font-weight: 700;
            color: #121760;
            transition: ease 0.3s color;
            margin-top: 10px;
            text-transform: uppercase;
        }

        &:hover {
            h3 {
                color: #1EA2CE;
            }
            .icon::after {
                opacity: 1;
            }
        }

        &.to-right {
            grid-area: 2/3/2;
        }

    }
}

@include mq($until: md){
    .about {
        padding-top: 80px;
        padding-bottom: 80px;

        &__service {
            .icon {    
                img {
                    max-width: 80px;
                }
            }
        }
        &__logos {
            grid-template-columns: repeat(2, 1fr);
        }
    }
}

@include mq($until: sm){
    .about {
        &__services {
            grid-column-gap: 30px;
            grid-row-gap: 30px;        
            grid-template-columns: repeat(2, 1fr);
        }
        &__service {

            .icon {    
                img {
                    max-width: 100px;
                }
            }

            &.to-right {
                grid-area: 3/1/3;
            }

        }
    }
}

@include mq($until: xs){
    .about {

        &.about-us {
            min-height: 60vh;
            padding-top: 100px;
            padding-bottom: 100px;
        }
    
        &__services {
            grid-column-gap: 15px;
            grid-row-gap: 15px;        
            grid-template-columns: 1fr;
        }
        &__service {

            .icon {    
                aspect-ratio: 4/2;

                img {
                    max-width: 100px;
                }
            }

            &.to-right {
                grid-area: 3/1/3;
            }

        }
    }
}

