.header {
  position: absolute;
  min-height: 100px;
  width: 100%;
  z-index: 2;

  &--fixed {
    position: fixed;
    width: 100%;
    transition: background-color .3s ease-in-out;

    &.sticky {
      background-color: lemonchiffon;
    }
  }

  &__main {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 100px;
  }

  &__logo{
    position: relative;
    z-index: 2;
    padding-right: 20px;
    img {
      height: auto;
    }
  }

  &__nav {
    &--fullscreen {
      width: 100%;
      height: 100vh;
      background-color: $color-extra;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      padding-top: 100px;
      transform: translate(0, -100%);
      transition: transform 0.3s ease-in-out;
    }
    &--visible {
      transform: translate(0, 0);
    }
  }

  &__topnav {

    .header__menuItem {
      background-color: rgba(255, 255, 255, 0.28);
      border-radius: 0 0 7px 7px;
      margin-left: 16px;
      margin-top: 0;
      transition: ease 0.3s background-color;

      &:hover {
        background-color: #1FA3CD;
      }
    }

    .header__menuLink {
      padding: 7px 17px;
      display: block;
      font-size : rem(12px);

      &:after {
        display: none;
      }
    }

  }

  &__menuList {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    &--fullscreen {
      flex-direction: column;
    }
  }

  &__menuItem {
    margin-left: 23px;
    padding: 0;
    &--fullscreen{
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-bottom: 30px;
    }
    &::before {
      display: none;
    }
  }

  &__menuLink {
    color: #fff;
    position: relative;
    font-weight: 700;
    font-size: rem(14px);
    white-space: nowrap;

    &--fullscreen {
      color: #ffffff;
      font-size: rem(28px);
    }
    &:active, &--active, &--open, &:hover {
      color: #fff;
    }
    &:after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -5px;
      width: 0;
      height: 3px;
      background-color: #80BA49;
      transition: ease 0.3s width;
    }
    &:active:after, &--active:after, &--open:after, &:hover:after {
      width: 100%;
    }
  }
}

body.home {
  .header {
    &__main {
      .header__menuList {
        .header__menuItem:not(:first-of-type) {
          .header__menuLink::after {
            width: 0;
          }
          .header__menuLink:hover:after {
            width: 100%!important;
          }
        }
      }
    }
  }
}

@include mq($until: xl){
  .header {
    &__main {
      .header__menuList {
        display: block;
        text-align: right;
       }
      .header__menuItem {
        display: inline-block;
      }
    }
  }
}

@include mq($until: md){
  .header {
    &__topnav {
      .header__menuList {
        flex-direction: row;
        padding: 0;

        .header__menuItem {
          margin-bottom: 0;
        }
      }
    }
    &__main {
      .header__menuList {
        text-align: left;

        .header__menuItem {
          display: block;
        }
        .header__menuLink {
          white-space: normal;
        }
      }
    }
  }
}

@include mq($until: sm){
  .header {
    &__topnav {
      .header__menuList {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: 1fr;
        grid-column-gap: 10px;
        grid-row-gap: 0px;
        text-align: center;
      }
      .header__menuItem {
        margin-left: 0;
      }
      .header__menuLink {
        padding: 7px 7px;
      }
    }
  }
}
