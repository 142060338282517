.hero {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 104vh;
    position: relative;
    display: flex;
    align-items: flex-end;

    &::after {
        content: '';
        background-image: url('/themes/default/assets/images/mask-bottom.svg');
        background-repeat: no-repeat;
        background-position: bottom center;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 100%;
    }

    .egg-wrap {
      position: absolute;
      left: 0;
      overflow: hidden;
      max-width: 100vw;
      width: 100%;
      text-align: right;
      z-index: 1;
      bottom: -140px;
    }

    .egg {
      right: -8vw;
      max-width: 52%;
      width: 100%;
      position: relative;

      [fill="#fff"] {
        fill: transparent;
      }
    }

    &__content {
      width: 51%;
      padding-bottom: 24vh;
      z-index: 2;

      h1 {
        font-size: rem(38px);
        font-weight: 700;
        color: #fff;
      }

      p {
        font-size: rem(16px);
        color: #fff;
      }

    }
}

.subsite-hero {
  background-position: center;
  background-size: cover;
  background-attachment: fixed;
  min-height: 700px;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &::after {
      content: '';
      background-image: url('/themes/default/assets/images/mask-bottom.svg');
      background-repeat: no-repeat;
      background-position: bottom center;
      position: absolute;
      bottom: -1px;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
  }

  h1 {
    font-size: rem(38px);
    font-weight: 700;
    color: #fff;
    position: relative;
    z-index: 1;
    text-transform: uppercase;
    margin-bottom: 0;
    max-width: 860px;
  }
  .icon {
    background-image: url('/themes/default/assets/images/icons/blob.svg');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 380px;
    aspect-ratio: 1;
    position: relative;
    z-index: 1;
    margin-bottom: -40px;

    img {
      max-width: 160px;
      width: 100%;
    }
  }

}

@include mq($until: xl){
  .hero {
    h1 {
      font-size: rem(28px);
    }
  }
}

@include mq($until: md){
  .hero {
    display: block;
    padding-top: 280px;
    min-height: unset;

    .egg-wrap {
        position: relative;
        top: 0;
        margin-bottom: -40px;
        padding-left: 15px;
        padding-right: 15px;
    }

    .egg {
        left: 0;
        right: 0;
        max-width: 100%;
        transform: none;        
        max-height: 380px;
        height: auto;
    }

    &__content {
      max-width: 800px;
      width: 100%;
      margin-right: auto;
      margin-left: auto;
      padding-bottom: 80px;
      padding-right: 30px;
      text-align: center;
    }
  }

  .subsite-hero {
    display: block;
    padding-top: 240px;
    text-align: center;
    min-height: unset;
    overflow: unset;

    h1 {
      font-size: rem(28px);
      margin-bottom: 40px;
    }

    .icon {
      margin: 0 auto;
      bottom: -60px;

      img {
        max-width: 280px;
        padding: 60px;
      }
    }
  }
}

@include mq($until: sm){
  .hero {
    &__content {
      padding-right: 15px;
    }
  }
}
