.article {
	display: flex;
	align-items: center;
	position: relative;
	margin-bottom: $padding-xl;


	&__text, &__image {
		width: 50%;
	}

	&__image {

		img {
			height: 720px;
			object-fit: cover;
			object-fit: contain;
			width: 100%;
		}

  	}

	&__text {
		padding: 0 5%;
		max-width: calc(535px + 10%);
	}

	.button {
		margin-left: auto;
		margin-top: 20px;
		min-width: 160px;
		display: block;
		width: fit-content;
	}

	@include mq($until: md) {

		flex-direction: column;
		margin-bottom: $padding-lg;

		&__text, &__image {
			width: 100%;
			padding: 0;
			max-width: 550px;
		}

		
		img {
			height: auto;
		}

	}

}
