.offer {
    background-position: center;
    background-size: cover;
    background-attachment: fixed;
    min-height: 130vh;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &--right {
        justify-content: flex-start;

        &::after {
            background-image: url('/themes/default/assets/images/mask-bottom-flipped.svg')!important;
        }
        &::before {
            background-image: url('/themes/default/assets/images/mask-top-flipped.svg')!important;
        }
        
        .offer__content {
            p, h2, .offer__link {
                margin-right: 0;
                margin-left: auto;
            }
        }

        .offer__link {
            text-align: unset;
            padding-right: 0;
        }
        .egg {
            left: unset!important;
            right: -8vw;
        }
        .egg-wrap {
            text-align: right!important;
        }

    }

    &::after {
        content: '';
        background-image: url('/themes/default/assets/images/mask-bottom.svg');
        background-repeat: no-repeat;
        background-position: bottom center;
        position: absolute;
        bottom: -1px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }
    &::before {
        content: '';
        background-image: url('/themes/default/assets/images/mask-top.svg');
        background-repeat: no-repeat;
        background-position: top center;
        position: absolute;
        top: -1px;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 0;
    }

    &__content {
        z-index: 2;
        position: relative;
        width: 42%;
        padding-bottom: 80px;

        h2 {
            color: #72B95A;
            margin-bottom: 40px;
            margin-right: auto;
            max-width: 580px;
            font-weight: 700;
            font-size: rem(34px);
        }
        p {
            color: #fff;
            margin-right: auto;
            max-width: 580px;
            margin-bottom: 80px;
            line-height: rem(28px);
            font-size: rem(16px);
        }
    }
    &__link {
        max-width: 580px;
        margin-right: auto;
        padding-right: 40px;
        text-align: right;
    }

    &__url {
        background-color: rgba(30, 164, 204, 0.66);
        padding: 9px 19px;
        color: #fff;
        border-radius: 9px;
        font-size: rem(13px);
        font-weight: 700;
        transition: ease 0.3s background-color;
        display: inline-block;
        cursor: pointer;

        &:hover {
            background-color: #1FA3CD;
            color: #fff;
        }
    }

    .egg-wrap {
        position: absolute;
        left: 0;
        overflow: hidden;
        max-width: 100vw;
        width: 100%;
        text-align: left;
        z-index: 1;
        top: -80px;
    }

    .egg {
        left: -8vw;
        max-width: 54%;
        width: 100%;
        position: relative;

        [fill="#fff"] {
            fill: transparent;
        }
    }
  
}


@include mq($until: xl){
    .offer {
        min-height: 114vh;

        .egg-wrap {
            top: -8vh;
        }

        .egg {
            max-width: 64%;
            right: 0;
            left: -16vw;
            transform: rotate(5deg);
        }

        &__content {
            width: 52%;
            padding-right: 0;
            padding-left: 40px;

            p, h2, .offer__link {
                margin-right: 0;
                margin-left: auto;
            }
        }
        &--right {
            .egg {
                right: -16vw;
                left: 0;
                transform: rotate(-5deg);
            }

            .offer__content {
                padding-right: 40px;
                padding-left: 0;

                p, h2, .offer__link {
                    margin-left: 0;
                }
            }
        }
    }
}

@include mq($until: md){
    .offer {
        min-height: unset;
        display: block;
        padding-bottom: 80px;

        .egg-wrap {
            position: relative;
            top: 0;
            margin-top: -40px;
        }

        .egg {
            left: 0;
            right: 0;
            max-width: 100%;
            transform: none;        
            max-height: 380px;
            height: auto;
        }

        &__content {
            width: 100%;
            padding-right: 0;
            padding-left: 0;
            padding-top: 40px;

            p, h2, .offer__link {
                max-width: 600px;
                margin-right: auto;
                margin-left: auto;
                text-align: center;
                padding-right: 0;
                padding-left: 0;
            }
            p { 
                margin-bottom: 40px;
            }
        }

        &--right {
            .offer__content {
                padding-right: 0;

                p, h2, .offer__link {
                    max-width: 600px;
                    margin-right: auto;
                    margin-left: auto;
                    text-align: center;
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}

@include mq($until: sm){
    .offer {
        padding-bottom: 40px;

    }
}
