.news{
    &__date{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        max-width: 1100px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        background: rgba(18, 23, 96, 0.05);
        padding: 20px;
        border-radius: 7px;
        svg {
            margin-right: 10px;
            fill: $paragraph-color;
            width: 28px;
        }
    }
    &__category{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        margin-bottom: 15px;
        max-width: 1100px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        p{
            font-weight: 600;
            margin-right: 5px;
        }
    }
    &__categories{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        a, span{
            &::after{
                content: '|';
                margin-left: 10px;
                margin-right: 10px;
            }
            &:last-of-type::after{
                display: none;
            }
        }
    }
    &__image{
        margin-bottom: 30px;
        text-align: center;
        max-width: 1100px;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        img {
            border-radius: 7px;
        }
    }
    .gallery{
        margin-top: 60px;
        margin-bottom: 30px;
    }
}