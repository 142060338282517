div#flags {
    margin-left: 30px;
  
    a {
      margin: 0 0 0 25px;
    }
  
    img {
        width: 30px;
        opacity: 0.5;
        transition: all .3s;
        &:hover {
            opacity: 1;
        }
    }
  
    .active-lang  {
        img {
            opacity: 1;
            box-shadow: 0 0 6px #00000040;
        }
    }
  
    @include mq($until: md) {
      position: absolute;
      right: 43px;
      top: 125%;
  
      a {
        margin: 0 7px;
      }
  
      img {
        width: 30px;
      }
    }
  
    @include mq($until: sm) {
      right: 30px;
    }
  
    @include mq($until: xs) {
      right: 10px;
    }
  }